import * as React from 'react';

interface CTABlockProps {
	h2: string;
	p: string;
	buttonText: string;
	buttonUrl: string;
}

const CTABlock: React.FC<CTABlockProps> = (props: CTABlockProps) => {
	return (
		<section className={'bg-bt-blue px-3 py-20'}>
			<div
				className={
					'container mx-auto flex max-w-4xl flex-col md:flex-row'
				}
			>
				<div
					className={
						'flex flex-col text-white max-md:items-center max-md:text-center md:pl-10'
					}
				>
					<h2 className={'font-rubik text-4xl font-bold'}>
						{props.h2}
					</h2>
					<p className={'pt-8 font-rubik text-2xl'}>{props.p}</p>
					<a href={props.buttonUrl}>
						<button
							className={
								'mt-6 rounded-full border px-6 py-4 font-rubik text-xl text-white hover:border-bt-red hover:bg-bt-red'
							}
						>
							{props.buttonText}
						</button>
					</a>
				</div>
			</div>
		</section>
	);
};

export default CTABlock;
