import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';

interface ImageCarouselProps {
	h2: string;
	images: Array<{
		imgSrc: string;
		topText: string;
		bottomText?: string;
	}>;
}
const ImageCarousel: React.FC<ImageCarouselProps> = (
	props: ImageCarouselProps
) => {
	SwiperCore.use([Autoplay, Pagination, Navigation]);
	return (
		<section className={'px-3 py-8 text-center	'}>
			<div
				className={
					'container mx-auto flex flex-col items-center justify-center text-bt-gray'
				}
			>
				<h2 className={'pt-8 font-rubik text-4xl'}>{props.h2}</h2>
			</div>
			<div className={'container mx-auto'}>
				<Swiper
					modules={[Navigation, Pagination]}
					className={'mb-8 w-[650px] max-w-full max-sm:mt-8'}
					spaceBetween={50}
					loop
					navigation
					pagination={{ clickable: true }}
					autoplay={{ delay: 3000 }}
					slidesPerView={1}
				>
					{props.images.map((image) => {
						return (
							<SwiperSlide>
								{!image.bottomText ? (
									<p
										className={
											'pt-10 pb-6 font-rubik text-xl'
										}
									>
										{image.topText}
									</p>
								) : (
									''
								)}
								<div
									className={[
										'px-20 pb-10 max-sm:py-0 max-sm:px-0',
										image.bottomText ? 'py-4' : ''
									].join(' ')}
								>
									<img src={image.imgSrc} />
								</div>
								{image.bottomText ? (
									<div className={'px-20 max-sm:px-0'}>
										<p
											className={
												'pb-6 font-rubik text-xl max-md:pt-6'
											}
										>
											{image.bottomText}
										</p>
										<p
											className={
												'pb-14 font-rubik text-xl font-bold'
											}
										>
											{image.topText}
										</p>
									</div>
								) : (
									''
								)}
							</SwiperSlide>
						);
					})}
				</Swiper>
			</div>
		</section>
	);
};

export default ImageCarousel;
