import * as React from 'react';
import Layout from '../../layouts/Layout';
import JobAlerts from '../../components/JobAlerts/JobAlerts';
import PageIntroSection from '../../components/PageIntroSection/PageIntroSection';
import MilitaryHero from '../../components/MilitaryHero/MilitaryHero';
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';
import TestimonialVideos from '../../components/TestimonialVideos/TestimonialVideos';

// @ts-ignore
import tireStack from '../../images/tire-stack.png';
// @ts-ignore
import slide1 from '../../images/meet-the-team-slide-1.jpg';
// @ts-ignore
import slide2 from '../../images/meet-the-team-slide-2.jpg';
// @ts-ignore
import slide3 from '../../images/meet-the-team-slide-3.jpg';
// @ts-ignore
import slide4 from '../../images/meet-the-team-slide-4.jpg';
// @ts-ignore
import slide5 from '../../images/meet-the-team-slide-5.jpg';

// @ts-ignore
import card1 from '../../images/serve-card-1.svg';
// @ts-ignore
import card2 from '../../images/serve-card-2.svg';
// @ts-ignore
import card3 from '../../images/serve-card-3.svg';
// @ts-ignore
import card4 from '../../images/serve-card-4.svg';
import JobSearchForm from '../../components/JobSearchForm/JobSearchForm';
import { graphql, useStaticQuery } from 'gatsby';
import BestPeopleSection from '../../components/BestPeopleSection/BestPeopleSection';
import ImageHero2 from '../../components/ImageHero2/ImageHero2';
// @ts-ignore
import heroImage from '../../images/meet-the-team-hero.png';
import ImageCTA from '../../components/ImageCTA/ImageCTA';
import CTABlock from '../../components/CTABlock/ImageCTA';

const TeamPage: React.FC = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'Positions',
			href: '/search/',
			active: false
		},
		{
			text: 'Our Story',
			href: '/story/',
			active: false
		},
		{
			text: 'Military',
			href: '/military/',
			active: false
		},
		{
			text: 'Benefits',
			href: '/benefits/',
			active: false
		},
		{
			text: 'Meet the Team',
			href: '/team/',
			active: false
		},
		{
			text: 'Career Path',
			href: '/career-path/',
			active: false
		}
	];
	const testimonials = [
		{
			videoURL: 'https://www.youtube.com/embed/OeY7GOrjG_8',
			name: 'Chance',
			position: 'Tire Technician',
			buttonText: 'Search Tire Technician Jobs',
			buttonLink: '/search/?term=Retail Tire Technician'
		},
		{
			videoURL: 'https://www.youtube.com/embed/-65wtwOWSv4',
			name: 'Paul',
			position: 'Auto Technician',
			buttonText: 'Search Auto Technician Jobs',
			buttonLink: '/search/?term=Retail Automotive Technician'
		},
		{
			videoURL: 'https://www.youtube.com/embed/l23sLBfogpk',
			name: 'Mike',
			position: 'Store Manager',
			buttonText: 'Search Management Jobs',
			buttonLink: '/search/?term=Retail Management'
		},
		{
			videoURL: 'https://www.youtube.com/embed/RdEL2cSLqbE',
			name: 'Vann',
			position: 'Warehouse Associate',
			buttonText: 'Search Warehouse Jobs',
			buttonLink: '/search/?term=warehouse'
		},
		{
			videoURL: 'https://www.youtube.com/embed/GxvUzsiUopY',
			name: 'Dawn',
			position: 'Route Driver',
			buttonText: 'Search Route Driver Jobs',
			buttonLink: '/search/?term=Commercial Route Driver'
		}
	];
	const carouselImages = [
		{
			imgSrc: slide1,
			topText: 'Tire Technician - Chance',
			bottomText:
				'“One of my favorite things about the job is that I come to work and get to learn new things every day. Another thing I like about Belle Tire is the fact that I get to work with my friends. Lastly, I love the sense of accomplishment when I help others in need! I look forward to future opportunities to help our customers and advance in the company.”'
		},
		{
			imgSrc: slide2,
			topText: 'Auto Technician - Paul',
			bottomText:
				'“I started my career out as a Tire Technican 7 years ago and have advanced to Master Auto Technician. One of the incentives of being a Master Certified Tech is that you get paid 23% parts and labor. Some of the reasons I choose to work at Belle Tire are the amazing benefits, great pay scale and I really get a sense of family working here.”'
		},
		{
			imgSrc: slide3,
			topText: 'Store Manager - Mike',
			bottomText:
				'“The thing I love most about doing what I do is helping people out and knowing I have the ability to make our customers’ days a little bit better. I have the exciting opportunity to mentor employees as they move up the ranks. I enjoy the lack of micromanaging, being empowered in the store and knowing we are trusted to make decisions on our own.”'
		},
		{
			imgSrc: slide4,
			topText: 'Warehouse Associate - Vann',
			bottomText:
				'“I’ve been working at the Belle Tire distribution center for 37 years. I enjoy the comradery, the work ethics of the people and the diversity. I take pride in knowing the people we have provided tires for are safe as they travel the roads. Since I started working at Belle Tire, it’s always been a family atmosphere and we continue to grow!”'
		},
		{
			imgSrc: slide5,
			topText: 'Route Driver - Dawn',
			bottomText:
				'“I’ve been working with Belle Tire for 18 years and value that the company believes in safety first. I get a sense of family being here. It started off small, we’ve really worked our way up through the years and it’s exciting to be part of a company that is expanding so quickly. I’m glad to be part of that and look forward to the journey.”'
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Belle Tire Careers'}
			desc={'Test'}
		>
			<ImageHero2
				bgImage={heroImage}
				h1={'Feel at home where you work'}
				h2={'Join our team of the best and brightest today.'}
			/>
			<PageIntroSection
				h2={'WHO WE ARE'}
				pTags={[
					{
						text: "Our guys at Belle Tire have more than 100 years of expertise in tires, wheels and auto service, but that commitment also stands out in everything from our philanthropy to finding the talented teams that deliver expert customer service in our stores. We're looking for the best people to join our team, offering customers a world-class experience when they visit our stores for tires, wheels and auto service."
					}
				]}
			/>
			<ImageCarousel h2={'The Team'} images={carouselImages} />
			<JobAlerts />
			<TestimonialVideos testimonials={testimonials} />
			<CTABlock
				h2={'Career Advancement'}
				p={
					'At Belle Tire, we are continuously looking for individuals who are interested in growing their career and moving up in the company. We offer training programs to help you turn an entry level position into a fulfilling career.'
				}
				buttonText={'Career Paths'}
				buttonUrl={'/career-path/'}
			/>
			<BestPeopleSection />
			<JobSearchForm data={data} />
		</Layout>
	);
};

export default TeamPage;
